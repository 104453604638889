import React from "react";
import "./WhyJoin.scss";

const WJItem = ({ icon, text }) => {
  return (
    <li className="wj__items__item">
      <div className="img">
        <h2>{icon}</h2>
        {/* <img src={icon} alt="" /> */}
      </div>
      <div className="wj__items__item__text">{text}</div>
    </li>
  );
};

const WhyJoin = () => {
  return (
    <div className="wj__main">
      <h3 className="wj__heading">Roles And Responsibilities</h3>
      <ul className="wj__items">
        <WJItem
          icon="1"
          text="Integration of Ethics: The primary goal of the Global Network is to integrate ethics into Medical and Health Professions Education, enabling students, faculties, and medical professionals to address ethical challenges in healthcare effectively."
        />
        <WJItem
          icon="2"
          text="Profound Ethical Training: The network fosters profound training in ethical principles through specialized courses designed in collaboration with experts in bioethics education and research.



"
        />
        <WJItem
          icon="3"
          text="Interdisciplinary Collaboration: By facilitating interdisciplinary collaborations, the network aims to bridge the gap between education and practice. It brings together experts and educators from diverse disciplines and countries to address complex ethical issues from multiple perspectives.

          "
        />
        <WJItem
          icon="4"
          text="Comprehensive Solutions: Through evidence-informed approaches, the network strives to develop comprehensive solutions to the ethical challenges and dilemmas faced in healthcare.


"
        />
        <WJItem
          icon="5"
          text="Reformed Teaching Methodology: The network ensures the implementation of evidence-based teaching methodologies for faculty and student development in Bioethics. This approach enhances the understanding of ethical principles and encourages individuals to reflect on their ethical values, beliefs, and the consequences of their actions.



"
        />
        <WJItem
          icon="6"
          text="Mindfulness and Reflection: The network encourages individuals to be mindful of the outcomes of their actions and choices concerning patients, peers, the public, and personal well-being. It fosters introspection on ethical values and beliefs.


"
        />
        <WJItem
          icon="7"
          text="Strengthening Bioethics: In addition to education, the network actively engages with decision-makers, stakeholders, and academic institutions. It aims to strengthen bioethics as a whole by supporting the creation of bioethical rules and regulations at the local, national, and international levels.

 
"
        />
        <WJItem
          icon="8"
          text="Global Impact: The Global Network aspires to have a global impact by empowering healthcare professionals, educators, and policymakers worldwide to navigate ethical challenges in healthcare, promoting ethical practices, and fostering a culture of ethical awareness and competence.
          "
        />
      </ul>
    </div>
    // - exclusive access with curated resource materials on
    // Integrated bioethics.      
  );
};

export default WhyJoin;
