import { React, useEffect } from "react";
import "../Style/Navbar.scss";
import $ from "jquery";
import VisibilitySensor from "react-visibility-sensor";

function Navbar() {
  useEffect(() => {
    $(".nav-link").on("click", () => {
      $(".navbar-toggler").click();

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  }, []);

  function onChange(isVisible) {
    !isVisible
      ? $(".navbar").addClass("fixed-top")
      : $(".navbar").removeClass("fixed-top");
  }

  // <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686414213/Logopit_1686414165618_bzzoz9.png" alt="" />

  return (
    <div className="Navbar">
      <VisibilitySensor onChange={onChange} scrollCheck={true}>
        <div
          className="d-flex justify-content-center align-items-center p-2"
          id="header"
        >
          <img
            src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730963/Logopit_1686414165618_bzzoz9_o2yiqp.png"
            alt="logo2"
            id="logo2"
            onClick={() => {
              window.location.assign("/");
            }}
          />
          <div className="clgHeading">
            <h2 id="clgName">
              GLOBAL NETWORK OF BIOETHICS, MEDICAL AND HEALTH PROFESSIONS EDUCATION, <br></br>MELBOURNE, AUSTRALIA
            </h2>
          </div>
        </div>
      </VisibilitySensor>

      {/* Navbar  */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      </nav>
    </div>
  );
}

export default Navbar;
