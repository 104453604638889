import React from "react";

function OurTeam() {
    return (
        <div className="ourTeam">
            <div className="heading">
                <h3>GLOBAL NETWORK OF BIOETHICS,</h3>
                <h3 className="green">MEDICAL AND HEALTH PROFESSIONS EDUCATION</h3>
            </div>

            <div className="row team-row">
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686468862/Prof_Ronal_Harden_vwtbuc.jpg" alt="" />
                    <p className="teamname">Professor Ronal Harden <br /> (UK)</p>
                    <p>Co-Chairs</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730132/Dr_Russell_DSouza_-_hsyx4t.jpg" alt="" />
                    <p className="teamname">Professor Russell D’Souza (Australia)</p>
                    <p>Co-Chairs</p>
                </div>
                    <div className="col-md-3">
                        <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686468921/Ricardo-Leon-Borquez-A_zxmmns.jpg" alt="" />
                        <p className="teamname">Professor Ricardo Leon-Borqez (Mexico)</p>
                        <p>Co-Chairs</p>
                        {/* <p>President World Federation of Medical Education (WFME)</p> */}
                </div>
            </div>

            <div className="heading">
                <h3>International Council</h3>
                {/* <h3 className="green">Chair</h3> */}
            </div>

            <div className="row team-row">

            <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1644003655/William_Pinsky_mlzlb7.jpg" alt="" />
                    <p className="teamname">Professor William Pinsky (USA)</p>
                    <p>President ECFMG</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981927/Professor_Mary_Mathew_wbgi02.jpg" alt="" />
                    <p className="teamname">Professor Mary Mathew (India)</p>
                    <p> Deputy Chair Department of Education ICB</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1703666940/tlgvcygdwum1nliqarsf.jpg" alt="" />
                    <p className="teamname">Dr Vedprakash Mishra <br /> (India)</p>
                    <p>Chair Association of Indian Medical and Health Professions Colleges of ICB</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686469043/Professor_Emiola_Oluwabunmi_Olapade-Olaopa_et3tvi.jpg" alt="" />
                    <p className="teamname">Professor Emiola Oluwabunmi Olapade-Olaopa (Nigeria)</p>
                    <p>President of the African Medical Schools Association</p>
                </div>


                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686469114/Dr.Marcos-Nunez-PAFAMS-President-Photo-682x1024_qyco9h.jpg" alt="" />
                    <p className="teamname">Dr Marcos A. Nunez (Dominican Republic)</p>
                    <p>President Pan-American Federation of Associations of Medical Schools (PAFAMS)</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686469138/Dr_Bonny_Dickinson_USA_lidfvr.jpg" alt="" />
                    <p className="teamname">Professor Bonny L. Dickinson (USA)</p>
                    <p> President International Association of Medical Science Educators (IAMSE)</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643981961/Prof_Gerhard_Fortwengel_e8nrii.png" alt="" />
                    <p className="teamname">Professor Gerhard Fortwengel (Germany)</p>
                    <p>Co-Chair Europe Department of Education ICB</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1643982033/Prof_Joseph_Thornton_Uni_of_Florida_USA_o5apkn.png" alt="" />
                    <p className="teamname">Professor Joseph Thornton (USA)</p>
                    <p>Co-Chair North America Department of Education ICB</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002106/Dr_Stacy_Gallin_USA_e9uxsx.jpg" alt="" />
                    <p className="teamname">Professor Stacy Gallin (USA)</p>
                    <p>Co-Chair International Students Department of Education ICB</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686729891/Prof_Madalena_Patricio_lshugq.jpg" alt="" />
                    <p className="teamname">Professor Madalena Patricio (Lisbon)</p>
                    <p>Co-Chair Middle East Department of Education ICB</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1644002331/Prof_Daniella_Kediar_rvs2gr.jpg" alt="" />
                    <p className="teamname">Professor Daniella Kediar (Israel)</p>
                    <p>Co-Chair Middle East Department of Education ICB</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686469433/WhatsApp_Image_2023-06-11_at_2.44.59_AM_jpn8gn.jpg" alt="" />
                    <p className="teamname">Professor Surapaneni Krishna Mohan (India)</p>
                    <p>Secretary GNBMHPE</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730095/Prof._Hon_Kam_Lun_Ellis_Hong_Kong_kpmo1q.png" alt="" />
                    <p className="teamname">Professor Hon Kam Lun Ellis (China)</p>
                    <p>The Chinese University of Hong Kong</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1702886869/whqt1xaywpb5vrqoukuu.jpg" alt="" />
                    <p className="teamname">Professor Titi Savitri Prihatiningsih (Indonesia)</p>
                    <p>Faculty of Medicine Universitas Gadjah Mada, Yogyakarta</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756936/Eric_sol4y3.png" alt="" />
                    <p className="teamname">Dr. Eric Holmboe <br /> (USA)</p>
                    <p>Chief Executive Officer of Intealth</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756937/Harm_siqxje.png" alt="" />
                    <p className="teamname">Dr Harm Peters <br /> (Berlin)</p>
                    <p> AMSE President </p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756938/Neil_y38g9t.png" alt="" />
                    <p className="teamname">Dr Neil Osheroff <br /> (USA)</p>
                    <p>Ex President of IAMSE</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756938/Peter_fgtfgw.png" alt="" />
                    <p className="teamname">Prof Peter de Jong <br /> (Netherlands)</p>
                    <p>President of IAMSE</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756937/Finn_soacdi.png" alt="" />
                    <p className="teamname">Prof Gabrielle Finn <br /> (UK)</p>
                    <p> Chair of ASME </p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756936/Aviad_tdfhwr.png" alt="" />
                        <p className="teamname">Prof Aviad Haramati <br /> (USA)</p>
                    <p>AMEE</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756936/Ayelet_sohv8c.png" alt="" />
                    <p className="teamname">Prof Ayelet Kuper <br /> (Canada)</p>
                    <p>AMEE </p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756937/Liley_wgvmrh.png" alt="" />
                    <p className="teamname">Prof Patricia Lilley <br /> (Dundee)</p>
                    <p>Medical Teacher</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756937/Johann_lnuocx.png" alt="" />
                    <p className="teamname">Prof Johann Malawana  <br /> (UK)</p>
                    <p>CEO Medics Academy   </p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756937/Jeff_ptvj2k.png" alt="" />
                    <p className="teamname">Dr Jeff Fritz KERN</p>
                    <p>National Network for Flourishing Medicine</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756938/Mossey_jfvs0i.png" alt="" />
                    <p className="teamname">Prof Peter Mossey  <br /> (UK)</p>
                    <p>University of Dundee</p>
                </div>

                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756936/Anne_fjhlzi.png" alt="" />
                    <p className="teamname">Dr Anne LLoyd </p>
                    <p>CEO of AMEE</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756936/Chintakka_liyjfz.png" alt="" />
                    <p className="teamname">Prof Chintakka Balasooriya</p>
                    <p>ANZAHPE</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756937/Genii_nhzfw4.png" alt="" />
                    <p className="teamname">Prof Genevieve Moineau <br /> (Canada)</p>
                    <p>VP WFME</p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756938/Sara_bqefsr.png" alt="" />
                    <p className="teamname">Dr  Sara Fletcher </p>
                    <p>CEO Physician Assistant Education Association </p>
                </div>
                <div className="col-md-3">
                    <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1729756936/Ahmad_ejemyy.png" alt="" />
                    <p className="teamname">Prof Ahmad Rumayyana <br /> (Riyadh)</p>
                    <p>President Association for Medical Education</p>
                </div>
            </div>
        </div>
    );
}

export default OurTeam;
