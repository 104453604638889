import React, { useEffect, useRef } from "react";
import "../.././Style/AboutUs/gn.scss";
import OurTeam from "./OurTeam";
import Vision from "./Vision/Vision";
import WhyJoin from "./whyjoin/WhyJoin";
import Objectives from "./Objectives/Vision"
import History from "./History/History";
import ContactGN from './ContactGn/ContactUs'
import Gallery from "./Gallery/Gallery";

function UCBSA() {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sectionRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="gnamain">
      <div 
        className="gnabottom animate-section"
        ref={el => sectionRefs.current[0] = el}
      >
        <div className="bottomcontent">
          <p>
          The Global Network of Medical, Health Professions and Bioethics Education (GNMHPB) was inaugurated on the 27th of December 2022 by Professor Ricardo Leon Borquez, current President of World Federation of Medical Education, in the Presence of Professor Ronald Harden, CEO AMEE and Editor of Medical Teacher the Chief Guest of the function, along with Dr William Pinsky President ECFMG USA and Professor David Gordon Past President WFME. Professionalism and ethical behaviour are now recognised as essential to practice of medicine and medical and health professions graduate must demonstrate the moral and ethical principles under-lying the profession including ethical decision making. The Global Network of Medical, Health and Bioethics Education is an international network of leaders of Medical, Health Professions and Bioethics Educators, associations and institutions dedicated to promoting and advancing Bioethics integrated in Medical and Health Professions Education.
          </p>
        </div>
      </div>

      <div 
        className="animate-section"
        ref={el => sectionRefs.current[1] = el}
      >
        <OurTeam />
      </div>
      <div 
        className="animate-section"
        ref={el => sectionRefs.current[2] = el}
      >
        <Vision />
      </div>
      <div 
        className="animate-section"
        ref={el => sectionRefs.current[3] = el}
      >
        <History />
      </div>
      <div 
        className="animate-section"
        ref={el => sectionRefs.current[4] = el}
      >
        <WhyJoin />
      </div>
      <div 
        className="animate-section"
        ref={el => sectionRefs.current[5] = el}
      >
        <Objectives />
      </div>
      <div 
        className="animate-section"
        ref={el => sectionRefs.current[6] = el}
      >
        <Gallery />
      </div>
      <div 
        className="animate-section"
        ref={el => sectionRefs.current[7] = el}
      >
        <ContactGN />
      </div>

      <style jsx>{`
        .animate-section {
          opacity: 0;
          transform: translateY(30px);
          transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        }

        .animate-section.visible {
          opacity: 1;
          transform: translateY(0);
        }
      `}</style>
    </div>
  );
}

export default UCBSA;
