import "./App.scss";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GN from "./components/GN/GN";
import PageNotFound from "./components/PageNotFound";


function App() {

  return (

    <Router>
      <div className="App">
        <Navbar />

        <Switch>

          <Route exact path="/">
            <GN />
          </Route>
          {/* 404 Page Not Found  */}
          <Route exact path="*">
            <PageNotFound />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
