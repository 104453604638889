import React, { useState, useEffect } from 'react'

const Gallery = () => {
  const galleryEvents = [
    {
      id: 1,
      eventName: "1st meet at Datta Meghe Medical College, Wanadongri Nagpur",
      images: [
        {
          id: 1,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730094757/WhatsApp_Image_2024-10-24_at_13.42.37_9997c398_ygvgp7.jpg",
          alt: "Inauguration ceremony"
        },
        {
          id: 2,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730094757/WhatsApp_Image_2024-10-24_at_13.42.36_ed3be6dd_j9lgyw.jpg",
          alt: "Inauguration group photo"
        },
        {
          id: 3,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730094757/WhatsApp_Image_2024-10-24_at_13.42.36_92b17f69_limy92.jpg",
          alt: "Inauguration group photo"
        }
      ]
    },
    {
      id: 2,
      eventName: "Dundee",
      images: [
        {
          id: 4,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095253/1729759772724_uwsjg6.jpg",
          alt: "Prof Ronald Harden's speech"
        },
        {
          id: 5,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095253/1729759772676_unqevj.jpg",
          alt: "Dr William Pinsky's speech"
        },
        {
          id: 6,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095253/1729759772773_oydbfe.jpg",
          alt: "Dr William Pinsky's speech"
        },
        {
          id: 7,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095252/1729759772902_protpz.jpg",
          alt: "Dr William Pinsky's speech"
        },
        {
          id: 8,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095251/1729759772852_odloth.jpg",
          alt: "Dr William Pinsky's speech"
        },
        {
          id: 9,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095252/1729759772813_e5h28p.jpg",
          alt: "Dr William Pinsky's speech"
        }
      ]
    },
    {
      id: 3,
      eventName: "Basel",
      images: [
        {
          id: 10,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095127/WhatsApp_Image_2024-10-24_at_14.07.49_47af959e_klquqs.jpg",
          alt: "Prof David Gordon's presentation"
        },
        {
          id: 11,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095127/WhatsApp_Image_2024-10-24_at_14.07.48_277d4fc9_r0kitn.jpg",
          alt: "Group photo"
        },
        {
          id: 12,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730095127/WhatsApp_Image_2024-10-24_at_14.07.49_e42ecd63_andcei.jpg",
          alt: "Group photo"
        }
      ]
    },
    {
      id: 4,
      eventName: "Prof Russell DSouza Australia alongwith  Prof Vedprakash Mishra and  Prof Mary Mathew and the team at 17 th IMEC 2024 Conference Kuala Lumpur Malaysia with some of the participants of the Workshop  on Innovative Strategies for  Student Engagement with One Health in Competency Based Medical Education",
      images: [
        {
          id: 13,
          src: "https://res.cloudinary.com/unesco-admin/image/upload/v1730115695/WhatsApp_Image_2024-10-28_at_15.35.04_e5213f0d_cyevbt.jpg",
          alt: "Group photo"
        } 
      ]
    }
  ];

  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentEventIndex((prevIndex) =>
        prevIndex === galleryEvents.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const currentEvent = galleryEvents[currentEventIndex];

  const handlePrevious = () => {
    setCurrentEventIndex((prevIndex) =>
      prevIndex === 0 ? galleryEvents.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentEventIndex((prevIndex) =>
      prevIndex === galleryEvents.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className='w-full min-h-screen bg-gray-100 py-12 mt-5'>
      <div className='max-w-7xl mx-auto px-4'>
        <div className='text-center mb-12 animate-fadeIn'>
          <h3 className='text-3xl font-bold mb-4 text-[#299702] animate-slideDown'>GALLERY</h3>
          <p className='text-lg text-gray-600 animate-slideUp'>Explore our collection of memorable moments</p>
        </div>

        <div className='text-center mb-8'>
          <h4 className='text-2xl font-semibold text-[#299702] animate-fadeIn'>{currentEvent.eventName}</h4>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 transition-all duration-500 ease-in-out'>
          {currentEvent.images.map((image, index) => (
            <div
              key={image.id}
              className='rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 animate-fadeIn'
              style={{
                animation: `fadeIn 0.5s ease-out ${index * 0.2}s`,
                opacity: 0,
                animationFillMode: 'forwards'
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                className='w-full h-64 object-cover transform transition-transform duration-300 hover:scale-105'
              />
            </div>
          ))}
        </div>

        <div className='flex justify-center mt-8 gap-4'>
          <button
            onClick={handlePrevious}
            className='px-6 py-2 bg-[#01471b] text-white rounded-full hover:bg-[#299702] transition-colors duration-300 transform hover:scale-105 active:scale-95'
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className='px-6 py-2 bg-[#01471b] text-white rounded-full hover:bg-[#299702] transition-colors duration-300 transform hover:scale-105 active:scale-95'
          >
            Next
          </button>
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        @keyframes slideDown {
          from { transform: translateY(-50px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        @keyframes slideUp {
          from { transform: translateY(50px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        .animate-fadeIn {
          animation: fadeIn 1s ease-out;
        }
        .animate-slideDown {
          animation: slideDown 1s ease-out;
        }
        .animate-slideUp {
          animation: slideUp 1s ease-out;
        }
      `}</style>
    </div>
  )
}

export default Gallery